const base = window.location.protocol === 'https:' ? 'https://s.sdcsinma.com/' : 'http://118.107.201.174/';
// const base ='https://s.sdcsinma.com/';

// const base = window.location.protocol === 'https:' ? 'https://moguserver.ace4dv2.live/' : 'http://localhost:8003/';
// const base = 'http://118.107.201.174/';
// const base = 'http://localhost:8003/';

async function massageRes(res, resolve) {
  const result = await res.json()
  if (result.error && result.error === 'Invalid credentials!') {
    resolve(result)
  } else {
    resolve(result)
  }
}

function loginFNC(username, password) {
  return new Promise((resolve) => {
    fetch(`${base}public/login`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function AddCustomerFNC(CustomerDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddCustomer`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ CustomerDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function AddPriceLevelFNC(PriceLevelDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddPriceLevel`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ PriceLevelDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function AddStockInfoFNC(StockInfoDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddStockInfo`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ StockInfoDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function AddProductFNC(ProductDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddProduct`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ProductDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function AddShelfFNC(ShelfDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddShelf`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ShelfDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function AddStoreFNC(StoreDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddStore`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ StoreDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function CreateUsersFNC(UserDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/CreateUsers`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function CreateUserGroupFNC(GroupDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/CreateUserGroup`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ GroupDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function AddCategoryFNC(CategoryDetails) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddCategory`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ CategoryDetails }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getCustomerDetailsFNC(SearchCustomer, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getCustomerDetails`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchCustomer, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getStockInfoFNC(SearchStockInfo, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getStockInfo`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchStockInfo, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function GetExcelDataFNC(SearchData, DatabaseType) {
  return new Promise((resolve) => {
    fetch(`${base}main/GetExcelData`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchData, DatabaseType }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getShelfMasterFNC(SearchShelf, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getShelfMaster`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchShelf, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getProductMasterFNC(SearchProduct, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getProductMaster`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchProduct, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getUserManagementFNC(SearchUsers, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUserManagement`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchUsers, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getUserGroupsFNC(SearchUserGroup, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUserGroups`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchUserGroup, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getCategoryFNC(SearchCategory, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getCategory`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchCategory, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function DeleteCustomerFNC(SelectedCustomer) {
  return new Promise((resolve) => {
    fetch(`${base}main/DeleteCustomer`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SelectedCustomer }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function DeleteUserGroupFNC(SelectedUserGroup) {
  return new Promise((resolve) => {
    fetch(`${base}main/DeleteUserGroup`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SelectedUserGroup }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateCustomerDetailsFNC(CustomerDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateCustomer`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ CustomerDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateStockInfoFNC(StockInfoDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateStockInfo`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ StockInfoDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdatePriceLevelFNC(PriceLevelDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdatePriceLevel`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ PriceLevelDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateShelfFNC(ShelfDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateShelf`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ShelfDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateProductMasterFNC(ProductDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateProductMaster`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ProductDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateUsersFNC(UserDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateUsers`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateUserGroupFNC(UserGroupDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateUserGroup`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserGroupDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getUserCreateGroupFNC() {
  return new Promise((resolve) => {
    fetch(`${base}main/getUserCreateGroup`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getDataFromFNC(getDatafrom) {
  return new Promise((resolve) => {
    fetch(`${base}main/getDataFrom`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ getDatafrom }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getStoreMasterFNC(SearchStore, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getStoreMaster`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchStore, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getPriceLevelFNC(SearchPriceLevel, page, countperpage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getPriceLevel`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchPriceLevel, page, countperpage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateCategoryFNC(CategoryDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateCategory`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ CategoryDetails, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateStoreFNC(StoreDetails, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateStore`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ StoreDetails, EditID }),
    }).then((res) => { massageRes(res, resolve) });
  })
}

function getDashbordDataFNC(Role) {
  return new Promise((resolve) => {
    fetch(`${base}main/getDashbordData`, {
      method: 'post',
      headers: {
        Authorization: 'KvEcvRJ9D8syGsI9',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Role }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function createDepartmentMasterF(username, DeptObject) {
  return new Promise((resolve) => {
    fetch(`${base}main/createDepartmentMaster`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, DeptObject }),
    }).then((res) => {
      // console.log('res', res)
      massageRes(res, resolve)
    });
  })
} 

function getDepartmentMasterList(SearchDepartment, pageNumber, countPerPage) {
  return new Promise((resolve) => {
    fetch(`${base}main/getDepartmentMasterList`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SearchDepartment, pageNumber, countPerPage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
} 

function UpdateDepartmentFNC(Details, EditID) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateDepartment`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Details, EditID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function DeleteDataFNC(SeletedDeleteDetails, DeletePageType) {
  return new Promise((resolve) => {
    fetch(`${base}main/DeleteData`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SeletedDeleteDetails, DeletePageType}),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
} 

function DeleteUserFNC(SelectedUsers) {
  return new Promise((resolve) => {
    fetch(`${base}main/DeleteUser`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SelectedUsers}),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
} 

export {
  DeleteDataFNC,
  getCustomerDetailsFNC,
  getStockInfoFNC,
  GetExcelDataFNC,
  getShelfMasterFNC,
  getUserManagementFNC,
  getProductMasterFNC,
  getUserGroupsFNC,
  getCategoryFNC,
  DeleteCustomerFNC,
  DeleteUserGroupFNC,
  UpdateCustomerDetailsFNC,
  UpdateStockInfoFNC,
  UpdatePriceLevelFNC,
  UpdateShelfFNC,
  UpdateProductMasterFNC,
  UpdateUsersFNC,
  UpdateUserGroupFNC,
  UpdateCategoryFNC,
  UpdateStoreFNC,
  getStoreMasterFNC,
  getPriceLevelFNC,
  getUserCreateGroupFNC,
  getDataFromFNC,
  getDashbordDataFNC,
  AddCustomerFNC,
  AddPriceLevelFNC,
  AddStockInfoFNC,
  AddProductFNC,
  AddShelfFNC,
  AddStoreFNC,
  loginFNC,
  AddCategoryFNC,
  CreateUsersFNC,
  CreateUserGroupFNC,
  createDepartmentMasterF,
  getDepartmentMasterList,
  UpdateDepartmentFNC,
  DeleteUserFNC,
};
  